.query .page-header .img {
  width: 42%;
  max-width: 143px;
}
.query__block {
  margin: 0;
  padding: 35px 15px;
}
.query__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.query__intro {
  padding: 35px 15px 30px;
}
.query__intro .inner {
  max-width: 900px;
}
.query__pages {
  padding: 0 15px 55px;
}
.query__pages .inner {
  max-width: 900px;
}
.query__pages .flexItem:first-child .query__item {
  margin-top: 0;
}
.query__pages .flexItem:last-child .query__item {
  margin-bottom: 0;
}
.query__item {
  margin: 53px 0;
}
.query__item .ttl {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.query__item .txt {
  margin-top: 15px;
}
.query__item .link {
  margin-top: 20px;
}
.query__item .link a {
  margin: 0 auto;
  width: 300px;
}

@media screen and (min-width: 768px) {
  .query .page-header .img {
    width: 100%;
    max-width: 290px;
    right: 40px;
  }
  .query__block {
    padding: 75px 15px 60px;
  }
  .query__intro {
    padding: 75px 15px 52px;
  }
  .query__intro p {
    text-align: center;
  }
  .query__pages {
    margin-top: -40px;
    padding: 0 15px 60px;
  }
  .query__pages .flexBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -40px;
  }
  .query__pages .flexItem {
    padding: 0 40px;
    width: 50%;
  }
  .query__item {
    margin: 40px 0 !important;
  }
  .query__item .ttl {
    margin-top: 30px;
    font-size: 24px;
  }
  .query__item .txt {
    margin-top: 20px;
  }
  .query__item .link {
    margin-top: 32px;
  }
  .query__item .link a {
    margin: 0 auto;
    width: 100%;
  }
}