.query{

	.page-header{

		.img{
			width: 42%;
			max-width: 143px;
		}
	}
	
	&__block{
		margin: 0;
		padding: 35px 15px;

		.inner{
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}
	}

	&__intro{
		padding: 35px 15px 30px;

		.inner{
			max-width: 900px;
		}
	}

	&__pages{
		padding: 0 15px 55px;

		.inner{
			max-width: 900px;
		}

		.flexItem{

			&:first-child .query__item{
				margin-top: 0;
			}
			&:last-child .query__item{
				margin-bottom: 0;
			}
		}
	}

	&__item{
		margin: 53px 0;

		.ttl{
			margin-top: 20px;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}

		.txt{
			margin-top: 15px;
		}

		.link{
			margin-top: 20px;

			a{
				margin: 0 auto;
				width: 300px;
			}
		}
	}

}
@media screen and (min-width:768px){
	.query{

		.page-header{

			.img{
				width: 100%;
				max-width: 290px;
				right: 40px;
			}
		}
	
		&__block{
			padding: 75px 15px 60px;
		}

		&__intro{
			padding: 75px 15px 52px;

			p{
				text-align: center;
			}
		}

		&__pages{
			margin-top: -40px;
			padding: 0 15px 60px;

			.flexBox{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 0 -40px;
			}

			.flexItem{
				padding: 0 40px;
				width: 50%;
			}
		}

		&__item{
			margin: 40px 0 !important;

			.ttl{
				margin-top: 30px;
				font-size: 24px;
			}

			.txt{
				margin-top: 20px;
			}

			.link{
				margin-top: 32px;

				a{
					margin: 0 auto;
					width: 100%;
				}
			}
		}
	}
}
